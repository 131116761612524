import { Component } from 'react';
import { connect } from 'react-redux'
import { fetchSiteSettingData } from './../store/actions/siteAction'
import { getCmsPagesApi } from '../container/Api/api'
import { Link } from 'react-router-dom'

import MoonPayModal from '../components/modals/MoonPayModal';
import CompanyLogo from './../assets/img/company-icon.svg';
import FacebookLogo from './../assets/img/facebook.png';
import InstagramLogo from './../assets/img/instagram.png';
import TwitterLogo from './../assets/img/twitter.png';
import DiscordLogo from './../assets/img/discord.png';
import YoutubeLogo from './../assets/img/youtube.png';
import ChatLogo from './../assets/img/chat-icon.png';
import MaticImage from './../assets/img/built-poly.png'

class Footer extends Component {

  state = {
    cmsData: [],
  }
  componentDidMount() {
    this.props.dispatch(fetchSiteSettingData())
    this.fetchMyAPI()
  }
  fetchMyAPI = async () => {
    let res = await getCmsPagesApi()
    let data = [];
    if (res.status === 200) {
      data = res.data.data;
      this.setState({ cmsData: data })
    } else if (res.data.status === 0) {
      this.setState({ cmsData: data })
    }
  }

  render() {
    const { loading, facebook, twitter, instagram, youtube, discord } = this.props
    return (
      <footer className='footer' style={loading ? { display: 'none' } : { display: 'block' }}>
        <div className='container'>
          <div className='follow-us'>
            <div className='btn-shadow'>
              <a href={twitter} target='_blank' className='btn follow'><span>FOLLOW US!</span></a>
            </div>
          </div>
          <div className='social-links' id="community">
            <ul>
              <li>
                <a href={twitter} target='_blank'>
                  <img src={TwitterLogo} alt='Twitter' className='hvr-float-shadow img-fluid' />
                </a>
              </li>
              <li>
                <a href={discord} target='_blank'>
                  <img src={DiscordLogo} alt='Discord' className='hvr-float-shadow img-fluid' />
                </a>
              </li>

              <li>
                <a href={youtube} target='_blank'>
                  <img src={YoutubeLogo} alt='Youtube' className=' hvr-float-shadow img-fluid' />
                </a>
              </li>
              <li>
                <a href={instagram} target='_blank'>
                  <img src={InstagramLogo} alt='Instagram' className=' hvr-float-shadow img-fluid' />
                </a>
              </li>
              <li>
                <a href={facebook} target='_blank'>
                  <img src={FacebookLogo} alt='Facebook' className='hvr-float-shadow img-fluid' />
                </a>
              </li>

            </ul>
          </div>
          <div className='footer-links'>
            <ul>
              <li><Link to='/category/music' className='hvr-float-shadow'>Music</Link></li>
              <li><Link to='/category/art' className='hvr-float-shadow'>Art</Link></li>
              <li><Link to='/category/film' className='hvr-float-shadow'>Film</Link></li>

              {this.state.cmsData.length > 0 ?
                this.state.cmsData.map((item, index) => {
                  return <li key={index}><Link to={`/page/${item.seo_url}`} className='hvr-float-shadow'>{item.meta_title}</Link></li>
                })
                : ''}
            </ul>
          </div>
        </div>

        <div className='platform-icons'>
          <div className='image'>
            <img src={MaticImage} alt='Matic' className='img-fluid' />
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='copyrights'>
              <p>Copyright © 2021 Nexus.Art. All rights reserved</p>
            </div>
            {/* <div className='bounce chat-icon'>
              <img src={ChatLogo} alt='Chat Icon' className='img-fluid' />
            </div> */}
          </div>
          <div className='company-logo'>
            <img src={CompanyLogo} alt='Nexus Logo' className='img-fluid' />
          </div>
        </div>
        <MoonPayModal />
      </footer>
    );
  }
}
const mapStateToProps = state => {
  return {
    loading: state.siteSettingReducer.loading,
    facebook: state.siteSettingReducer.facebook,
    twitter: state.siteSettingReducer.twitter,
    discord: state.siteSettingReducer.discord,
    instagram: state.siteSettingReducer.insta,
    youtube: state.siteSettingReducer.youtube
  }
}
export default connect(mapStateToProps)(Footer)