import './Loader.css'
export default function Loader() {
  return <div className="loader-wrapper">
    <div className="loader">
      <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">sub
        <path d="M100.19 53.2317C93.7818 58.1962 93.474 67.6211 99.2061 73.3532L184.129 158.277C188.974 163.121 196.571 163.656 202.54 160.293C235.692 141.616 276.303 141.616 309.454 160.293C315.423 163.656 323.021 163.121 327.865 158.277L412.789 73.3532C418.521 67.6211 418.213 58.1962 411.804 53.2317C320.186 -17.7439 191.807 -17.7439 100.19 53.2317Z" fill="#7240A8"></path>
        <path d="M458.77 100.198C453.805 93.7895 444.381 93.4817 438.648 99.2138L353.725 184.137C348.881 188.982 348.346 196.579 351.709 202.548C370.385 235.7 370.385 276.31 351.709 309.462C348.346 315.431 348.881 323.029 353.725 327.873L438.648 412.796C444.381 418.528 453.805 418.221 458.77 411.812C529.746 320.194 529.746 191.815 458.77 100.198Z" fill="#A54B9F"></path>
        <path d="M327.865 353.727C323.021 348.883 315.423 348.348 309.454 351.711C276.303 370.387 235.692 370.387 202.54 351.711C196.571 348.348 188.974 348.883 184.129 353.727L99.2061 438.651C93.474 444.383 93.7818 453.808 100.19 458.772C191.807 529.748 320.186 529.748 411.804 458.772C418.213 453.808 418.521 444.383 412.789 438.65L327.865 353.727Z" fill="#D25598"></path>
        <path d="M160.293 202.548C163.656 196.579 163.121 188.982 158.277 184.137L73.3532 99.2138C67.6211 93.4817 58.1962 93.7895 53.2317 100.198C-17.7439 191.815 -17.7439 320.194 53.2317 411.812C58.1962 418.221 67.6211 418.528 73.3532 412.796L158.277 327.873C163.121 323.029 163.656 315.431 160.293 309.462C141.616 276.31 141.616 235.7 160.293 202.548Z" fill="#DF5795"></path>

      </svg>
    </div>
  </div>
}
