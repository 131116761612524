import { Redirect } from 'react-router-dom'
import { supportedNetworkVersions, supportedNetworks } from './../../constant/constant'
import { Message } from '../../utils/message'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import StickyVideo from 'react-sticky-video';
import 'react-sticky-video/dist/index.css';

const $ = window.jQuery

function GettingStarted(props) {

  let connected = Boolean(localStorage.getItem('connectd'))
  let token = Boolean(localStorage.token)
  const [redirect, setRedirect] = useState(false)
  const [connect, setConnect] = useState(false)

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [props.walletConnection])

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [token, props.token])

  const openTab = () => {
    if (!connected || connected === null || supportedNetworkVersions.indexOf(window.ethereum.networkVersion) === -1) {
      Message('error', 'Sorry', "Please Connect to supported network (" + supportedNetworks + ") first or connect wallet.")
      return;
    } else {
      setRedirect(true)
    }
  }

  const openSignUpTab = (param) => {
    $(`#${param}`).tab('show');
  }

  if (redirect) {
    return <Redirect to='/create-item' />
  } return (
    <section className="getting-started">
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-flex align-items-center">
            <div className="content">
              <h2>Getting Started with Music NFT Platform Nexus.Art</h2>
              <p><em>Want an easy way to understand NFTs?</em> David Bowie, always the consummate innovator, <em>basically invented NFTs!</em> So let’s give credit where credit’s due. His <strong>Bowie Bonds</strong> allowed you to invest in him as an artist &amp; performer, using as collateral the royalty streams from current and future album sales and live performances by <strong>David Bowie.</strong></p>
              {/* <StickyContainer></StickyContainer> */}
              <div className="mb-5 video">
                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/FiK7s_0tGsg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}

                <StickyVideo 
                  url={props.homeVideo}
                  playerVars={{
                    autoplay: false
                  }}
                  width={560}
                  height={315}
                  originalControls={true}
                  stickyConfig={{
                    width: 320,
                    height: 315,
                    position: 'top-right',
                  }}
                />

              </div>


              {token ? <button onClick={connect ? openTab : () => { }} className="btn-default hvr-bounce-in"><span className="icon">
                <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
              </span>{connect ? "Get Started" : 'No Wallet Connected'}</button> :
                <button className="btn-default hvr-bounce-in" data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab')} >
                  <span className="icon">
                    <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
                  </span>Join Now </button>}
            </div>
          </div>
          <div className="col-md-5">
            <div className="image">
              <img src={props.Image} alt="Getting Started with NFTs" className="bounce img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = state => {
  return {
    walletConnection: state.userReducer.walletConnection,
    token: state.userReducer.token,
    homeVideo: state.siteSettingReducer.homeVideo,
  }
}

export default connect(mapStateToProps)(GettingStarted)