import { Redirect } from 'react-router-dom'
import { supportedNetworkVersions, supportedNetworks } from './../../constant/constant'
import { Message } from '../../utils/message'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
const $ = window.jQuery
function Ecommerce(props) {

  let connected = Boolean(localStorage.getItem('connectd'))
  let token = Boolean(localStorage.token)
  const [redirect, setRedirect] = useState(false)
  const [connect, setConnect] = useState(false)

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [props.walletConnection])

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [token, props.token])

  const openTab = () => {
    if (!connected || connected === null || supportedNetworkVersions.indexOf(window.ethereum.networkVersion) === -1) {
      Message('error', 'Sorry', "Please Connect to supported network (" + supportedNetworks + ") first or connect wallet.")
      return;
    } else {
      setRedirect(true)
    }
  }

  const openSignUpTab = (param) => {
    $(`#${param}`).tab('show');
  }

  if (redirect) {
    return <Redirect to='/create-item' />
  } return (
    <section className="art-commerce main-padding">
      <div className="row">
        <div className="col-md-6 col-md-6 d-flex align-items-center">
          <div className="content">
            <h1>THE NEXUS OF ART &amp; COMMERCE</h1>
            <p>Nexus.Art is here to democratize the process of monetization for musicians & artists, finally making the dream of the independent artist a reality.</p>
            <p>Nexus.art offers a true opportunity for those investors who have the foresight to support the artists who they truly believe in… naturally it’s a vote of confidence in their future work. So you’re willing to stake your home on Bitcoin but not on your favorite artist or game developer? Let’s ignore the recent FOMO & FUD & get to the point. NFTs truly have the potential to shift the paradigm & change the world. </p>
            {token ? <button onClick={connect ? openTab : () => { }} className="btn-default hvr-bounce-in"><span className="icon">
              <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
            </span>{connect ? "Start Selling" : 'No Wallet Connected'}</button> :
              <button className="btn-default hvr-bounce-in" data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab')} >
                <span className="icon">
                  <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
                </span>Join Now </button>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="image">
            <img src={props.Image} alt="Art & Commerce" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = state => {
  return {
    walletConnection: state.userReducer.walletConnection,
    token: state.userReducer.token
  }
}

export default connect(mapStateToProps)(Ecommerce)