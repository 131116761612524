import beeple from './../../assets/img/beeple.jpg'

export default function AboutAndWhy({ Image }) {
  return (
    <section className="about-why">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="image">
              <img src={Image} alt="About &amp; Why Nexus" className="img-fluid" />
            </div>
            <div className="follow-us">
              <div className='btn-shadow'>
                <a className='btn follow hvr-bounce-in'><span>BUILT ON POLYGON / MATIC</span></a>
              </div>
            </div>
          </div>
          <div className="col-md-7 d-flex align-items-center">
            <div className="content">
              <h2>NEXUS.ART Free nft minting</h2>
              <p><b>You can start minting your own NFTs right away</b>  by signing up as a creator.</p>
              <p>NFTs simply give you the freedom to ID your digital creations as your own… the authentic &amp; original. Think of it as an easy way to trademark &amp; copyright your work in perpetuitum with e<em>veryone who possesses a copy of it always linked directly back to you.</em> You can also designate any percentage you want to be paid on the resale of your art.</p>
              <p><b>The Nexus platform</b>  exists to empower both creators & consumers, offering an open marketplace for NFT music, art, games, films & immersive or VR experiences. We’re a the place to securely & successfully market your original body of work or invest in the creators you know & love.</p>
              <p><em><strong>But we’re not trying to convince you, we’re sure you’ve already heard the news.</strong></em></p>

              <div className="image">
                <img src={beeple} alt="" className="img-fluid" />
              </div>
              <h2 className="text-center mt-2">Beeple sells NFT for 69 million $</h2>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
